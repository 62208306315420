// Filename - Footer.js

import React, { useEffect, useState } from "react";
import logo from "../assets/logo-cercle-portfolio-removebg.png";

import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export default function Footer() {
  const { t } = useTranslation("translation", { keyPrefix: "nav" });

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        className="Nav flex-column nav-foot"
        style={{ color: "white" }}
      >
        <Container className={isSmallScreen && "flex-column"}>
          <Navbar.Brand
            href="/"
            className={isSmallScreen && "flex-column h-auto"}
          >
            <img
              src={logo}
              alt="logo"
              width={isSmallScreen && 100}
              className={isSmallScreen && "m-auto"}
            />
            <p
              className="d-flex align-items-end ms-3"
              style={{ color: "white" }}
            >
              Lucas Yvernaux
            </p>
          </Navbar.Brand>

          <Nav
            style={{ color: "white" }}
            fill
            className={
              isSmallScreen
                ? ""
                : "me-auto my-2 my-lg-0 w-100 justify-content-end gap-5"
            }
          >
            <Nav.Link href="/">{t("home")}</Nav.Link>

            <Nav.Link href="/Projects">{t("projects")}</Nav.Link>

            <Nav.Link href="/About">{t("about")}</Nav.Link>
            <Nav.Link href="/Contact">{t("contact")}</Nav.Link>
          </Nav>
        </Container>
        <div>
          <Icon.CCircle size={20} className="me-2 my-3" />
          2025 - YVERNAUX - {t("footer")}
        </div>
      </Navbar>
    </>
  );
}
